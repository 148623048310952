import { useEffect, useState } from "react"
import { getShops } from "../../api/shopsApi"
import { ShopDto } from "../../models/api/shopApiTypes"
import ShopCard from "../common/ShopCard"
import ShopsPagination from "./ShopsPagination"
import { TownDto } from "../../models/api/districtsApiTypes"
import { CategoryDto } from "../../models/api/categoriesApiTypes"
import { getDistricts } from "../../api/districtsApi"
import { getCategories } from "../../api/categoriesApi"
import ShopsFilter from "./ShopsFilter"

export default function ShopsList() {
  const SHOPS_PER_PAGE = 120
  const [shops, setShops] = useState<ShopDto[]>()
  const [page, setPage] = useState(1)
  const [totalShopsCount, setTotalShopsCount] = useState<number>()
  const totalPages = totalShopsCount !== undefined ? Math.ceil(totalShopsCount / SHOPS_PER_PAGE) : 0
  const [towns, setTowns] = useState<TownDto[]>([])
  const [categories, setCategories] = useState<CategoryDto[]>([])
  const [townsFilter, setTownsFilter] = useState<string[]>([])
  const [categoriesFilter, setCategoriesFilter] = useState<string[]>([])

  useEffect(() => {
    getShops({ page, pageSize: SHOPS_PER_PAGE, categories: categoriesFilter, towns: townsFilter, randomize: false })
      .then((result) => {
        setShops(result.shops)
        setTotalShopsCount(result.totalCount)
      })
      .catch(() => console.log("Error loading shops"))
  }, [page, categoriesFilter, townsFilter])

  useEffect(() => {
    getDistricts().then((districts) => {
      setTowns(districts.map((d) => d.towns).flat())
    })
    getCategories().then((c) => setCategories(c))
  }, [])

  return (
    <>
      {towns !== undefined && categories !== undefined ? (
        <ShopsFilter
          towns={towns}
          categories={categories}
          townsFilter={townsFilter}
          setTownsFilter={setTownsFilter}
          categoriesFilter={categoriesFilter}
          setCategoriesFilter={setCategoriesFilter}
        />
      ) : null}
      <div className="row mx-auto" style={{ maxWidth: "1440px" }}>
        {shops !== undefined && shops.length > 0 ? (
          shops.slice(0, SHOPS_PER_PAGE).map((shop) => (
            <div className="col-12 col-md-6 col-lg-4 col-xl-3 mt-3 mb-3" key={shop.id}>
              <ShopCard
                shopImg={shop.photos}
                shopLogo={shop.company.logo}
                shopTitle={shop.name}
                shopDescription={shop.description}
                shopCategory={shop.category}
                shopLocation={shop.address}
                shopPhone={shop.phoneNumber}
                shopWebsite={shop.website}
                shopTown={shop.town}
              />
            </div>
          ))
        ) : shops !== undefined ? (
          <div className="col-12 text-center mt-4 mb-4">
            <h1>Nessun negozio trovato</h1>
            <p className="main-text">Prova a cambiare i filtri impostati</p>
          </div>
        ) : null}
      </div>
      {totalPages > 1 ? <ShopsPagination page={page} setPage={setPage} totalPages={totalPages} /> : null}
    </>
  )
}

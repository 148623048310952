import cn from "classnames"
import { useEffect, useRef, useState } from "react"

interface ShopCardDescriptionProps {
  description: string
}

export default function ShopCardDescription({ description }: ShopCardDescriptionProps) {
  const [isMobile, setIsMobile] = useState(false)
  const [isTextTruncated, setIsTextTruncated] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const textRef = useRef<HTMLParagraphElement | null>(null)

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768) // Mobile devices threshold (768px)
    }
    checkScreenSize()

    const element = textRef.current
    if (element) {
      // Compare scrollHeight and clientHeight
      const isTextTruncated = element.scrollHeight > element.clientHeight
      if (isTextTruncated) setIsTextTruncated(true)
    }

    // Add resize listener to detect screen size changes
    window.addEventListener("resize", checkScreenSize)

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener("resize", checkScreenSize)
    }
  }, [textRef.current])

  const toggleReadMore = () => {
    setIsExpanded((prev) => !prev)
  }

  return (
    <div>
      {!isMobile ? (
        <p className="shop-card-description ms-4 me-4 mb-3" title={description}>
          {description}
        </p>
      ) : (
        <>
          <p
            ref={textRef}
            className={cn(" ms-4 me-4 ", {
              "mb-3": !isTextTruncated,
              "mb-0": isTextTruncated,
              "shop-card-description": isMobile,
              "shop-card-description-mobile-expanded": isExpanded,
              "shop-card-description-mobile-collapsed": !isExpanded,
            })}
          >
            {description || ""}
          </p>
          {isTextTruncated ? (
            <p>
              <a className="ms-4 shop-card-read-more-text" onClick={toggleReadMore}>
                {isExpanded ? "Leggi meno" : "Leggi tutto"}
              </a>
            </p>
          ) : null}
        </>
      )}
    </div>
  )
}

import cn from "classnames"
import ShopCardImageCarousel from "./ShopCardImageCarousel"
import "../../assets/css/shopcard.css"
import ShopCardDescription from "./ShopCardDescription"

interface ShopCardProps {
  shopImg: string[]
  shopLogo: string
  shopTitle: string
  shopDescription: string
  shopCategory: string
  shopLocation: string
  shopPhone: string
  shopWebsite: string
  shopTown: string
}

export default function ShopCard({
  shopImg,
  shopLogo,
  shopTitle,
  shopDescription,
  shopCategory,
  shopLocation,
  shopPhone,
  shopWebsite,
  shopTown,
}: Readonly<ShopCardProps>) {
  return (
    <div className="shop-card mx-auto">
      <div className="shop-card-img mt-3">
        <div
          className="shop-card-logo"
          style={{
            background: `url("${shopLogo}")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <ShopCardImageCarousel img={shopImg} />
      </div>
      <p className="shop-card-title ms-4 me-4 mt-3 mb-0">{shopTitle}</p>
      <ShopCardDescription description={shopDescription} />
      <div className="ms-4 shop-card-tags-container">
        <span className="shop-card-category">{shopCategory}</span>
        <span className="shop-card-category ms-2">{shopTown}</span>
      </div>

      <div className="d-flex me-4 mb-4 shop-card-icons-container">
        {shopLocation !== null ? (
          <div className="ms-auto shop-card-icon">
            <a href={`http://maps.google.com/?q=${shopLocation}`} target="_blank" rel="noreferrer">
              <img src={`${process.env.PUBLIC_URL}/img/icons/location.svg`} alt="Location" style={{ width: "24px" }} />
            </a>
          </div>
        ) : null}
        {shopPhone !== null ? (
          <div className={cn("ms-4 shop-card-icon", { "ms-auto": shopLocation === undefined })}>
            <a href={`tel:${shopPhone}`}>
              <img src={`${process.env.PUBLIC_URL}/img/icons/phone.svg`} alt="Phone" style={{ width: "24px" }} />
            </a>
          </div>
        ) : null}
        {shopWebsite !== null ? (
          <div
            className={cn("ms-4 shop-card-icon", { "ms-auto": shopLocation === undefined && shopPhone === undefined })}
          >
            <a href={shopWebsite} target="_blank" rel="noreferrer">
              <img src={`${process.env.PUBLIC_URL}/img/icons/website.svg`} alt="Website" style={{ width: "24px" }} />
            </a>
          </div>
        ) : null}
      </div>
    </div>
  )
}

import { Route, Routes } from "react-router-dom"
import Home from "./pages/Home"
import RegistrationPage from "./pages/RegistrationPage"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "react-loading-skeleton/dist/skeleton.css"
import ShopsPage from "./pages/ShopsPage"
import "bootstrap/dist/js/bootstrap.bundle"
import FaqPage from "./pages/FaqPage"

function App() {
  return (
    <div className="App">
      <div className="container-fluid">
        <Routes>
          <Route path="/negozi" element={<ShopsPage />} />
          <Route path="/registrazione" element={<RegistrationPage />} />
          <Route path="/domande-frequenti" element={<FaqPage />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </div>
      <ToastContainer />
    </div>
  )
}

export default App
